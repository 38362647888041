/* interiro dummy data list :-
- Navbar
- Banner
- Feature
- About
- Projects
- Team
- News
- Testimonial
- Gallery
- Newsletter
- Footer
  - menu widget
  - copyright
  - social links
*/
/* ------------------------------------ */
// Menu data
/* ------------------------------------ */
export const menuData = [
  {
    label: 'About',
    path: '#aboutUs',
    offset: '80',
  },

  {
    label: 'News',
    path: '#news',
    offset: '40',
  },
];

/* ------------------------------------ */
// Banner section data
/* ------------------------------------ */
/* ------------------------------------ */
// About section data
/* ------------------------------------ */
import parentImg from 'common/assets/image/interior/about_parent.png';
/* ------------------------------------ */
// Feature section data
/* ------------------------------------ */
import corporateIcon from 'common/assets/image/interior/feature/1.svg';
import landscapeIcon from 'common/assets/image/interior/feature/2.svg';
import interiorIcon from 'common/assets/image/interior/feature/3.svg';
/* ------------------------------------ */
// Gallery data
/* ------------------------------------ */
import galleryImg1 from 'common/assets/image/interior/gallery/1.jpg';
import galleryImg2 from 'common/assets/image/interior/gallery/2.jpg';
import galleryImg3 from 'common/assets/image/interior/gallery/3.jpg';
import galleryImg4 from 'common/assets/image/interior/gallery/4.jpg';
import galleryImg5 from 'common/assets/image/interior/gallery/5.jpg';
import logo from 'common/assets/image/interior/mnhd.svg';
/* ------------------------------------ */
// Projects section data
/* ------------------------------------ */
import project1 from 'common/assets/image/interior/projects/1.png';
import slide1 from 'common/assets/image/interior/slider/slide-1.png';
import slide2 from 'common/assets/image/interior/slider/slide-2.png';
import slide3 from 'common/assets/image/interior/slider/slide-3.png';
import member1 from 'common/assets/image/interior/team/member1.jpg';
import member2 from 'common/assets/image/interior/team/member2.jpg';
import member3 from 'common/assets/image/interior/team/member3.jpg';
/* ------------------------------------ */
// Team section data
/* ------------------------------------ */
import { Icon } from 'react-icons-kit';
import { dribbble } from 'react-icons-kit/fa/dribbble';
/* ------------------------------------ */
// Footer data
/* ------------------------------------ */
import { facebook } from 'react-icons-kit/fa/facebook';
import { googlePlus } from 'react-icons-kit/fa/googlePlus';
import { skype } from 'react-icons-kit/fa/skype';
import { twitter } from 'react-icons-kit/fa/twitter';
import { socialDribbble } from 'react-icons-kit/ionicons/socialDribbble';
import { socialFacebook } from 'react-icons-kit/ionicons/socialFacebook';
import { socialGoogleplus } from 'react-icons-kit/ionicons/socialGoogleplus';
import { socialSkype } from 'react-icons-kit/ionicons/socialSkype';
import { socialTwitter } from 'react-icons-kit/ionicons/socialTwitter';

export const bannerData = {
  discount: '42%',
  discountLabel: 'DISCOUNT UP TO',
  title: 'The rhythm is yours',
  text: 'Select from various design and villas types.',
  carousel: [
    {
      id: 1,
      thumb_url: slide1,
      title: 'Restroom',
      link: '#1',
    },
    {
      id: 2,
      thumb_url: slide2,
      title: 'Livingroom',
      link: '#1',
    },
    {
      id: 3,
      thumb_url: slide3,
      title: 'Corner',
      link: '#1',
    },
  ],
};

export const featureData = {
  title: 'OUR SERVICES',
  slogan: 'Featured Service that We Provide',
  features: [
    {
      id: 1,
      icon: corporateIcon,
      title: 'Corporate Design',
      description:
        'Corporate design is a crucial part of your brand. Our approach corporate design is extraordinary.',
    },
    {
      id: 2,
      icon: landscapeIcon,
      title: 'Landscape Design',
      description:
        'Learn more about landscape plans, how to design them, what to watch out for in your layout.',
    },
    {
      id: 3,
      icon: interiorIcon,
      title: 'Interior Design',
      description:
        'Discover our design ideas, beautiful photos and how-to projects to create Interior Design.',
    },
  ],
};

export const aboutData = {
  thumb_url: parentImg,
  title: 'ABOUT THE DEVELOPER',
  text: 'MADINET MASR FOR HOUSING & DEVELOPMENT (MNHD) IS RECOGNIZED FOR 60 YEARS WORTH OF HISTORY AND EXPERTISE IN REAL ESTATE DEVELOPMENT. OVER THE YEARS, ITS TEAM OF GLOBAL SPECIALISTS AND LOCAL TALENT HAVE DEVELOPED A GROUP OF MEGA PROJECTS THAT CATER TO A DIVERSE PORTFOLIO OF CLIENTELE.',
  text2:
    'AMONG ITS MOST RECOGNIZED VENTURES IN NEW CAIRO BESIDES SARAI, IS TAJ CITY, WHICH IS LOCATED ON THE EXTENSION OF EL THAWRA ST. (HELIOPOLIS), SETS AN EXAMPLE OF BRILLIANCE. WITH ITS EVOLUTION IN ADEPT INFRASTRUCTURE AND UTILITIES, MNHD IS SETTING-FORTH A NEW ERA OF REAL ESTATE IN EGYPT.',
};

export const projectData = {
  title: 'WORK PROCEDURE',
  slogan: 'How We Do Our Projects',
  thumb_url: project1,
  projects: [
    {
      id: 1,
      text: 'Create a mind map to brainstorm your project to inspire new ideas. Use a simple piece of paper, poster, or whiteboard for your mind map and we write the destination of your project in the middle.We  Write down related topics, subtopics, and relevant concepts around the goal and branch from them to create and follow different tangents.',
      text2:
        'When you conduct a group project, each group member can benefit from the ideas of others and gain new insights by brainstorming together.',
    },
    {
      id: 2,
      text: 'Schedule a brainstorming session in a quiet place with few distractions. If you are working on a project alone, you can use group discussions with friends or colleagues to gain a new perspective on the topic. When you conduct a group project, each group member can benefit from the ideas of others and gain new insights by brainstorming together.',
      text2:
        'Completing a successful project requires a significant amount of time and work, which means evaluating your priorities and planning your course of action.',
    },
    {
      id: 3,
      text: 'Create a mind map to brainstorm your project to inspire new ideas. Use a simple piece of paper, poster, or whiteboard for your mind map and we write the destination of your project in the middle.We  Write down related topics, subtopics, and relevant concepts around the goal and branch from them to create and follow different tangents.',
      text2:
        'When you conduct a group project, each group member can benefit from the ideas of others and gain new insights by brainstorming together.',
    },
  ],
};

export const teamData = {
  title: 'OUR TEAM MEMBER',
  slogan: 'Meet Our Perfectionist',
  members: [
    {
      id: 1,
      avatar: member1,
      name: 'Roman Ul Oman',
      designation: 'Project Manager',
      social_links: [
        {
          id: 1,
          icon: <Icon icon={socialFacebook} />,
          url: '#1',
        },
        {
          id: 2,
          icon: <Icon icon={socialDribbble} />,
          url: '#1',
        },
        {
          id: 3,
          icon: <Icon icon={socialGoogleplus} />,
          url: '#1',
        },
        {
          id: 4,
          icon: <Icon icon={socialSkype} />,
          url: '#1',
        },
        {
          id: 5,
          icon: <Icon icon={socialTwitter} />,
          url: '#1',
        },
      ],
    },
    {
      id: 2,
      avatar: member2,
      name: 'Jeny Doe',
      designation: 'Lead Designer',
      social_links: [
        {
          id: 1,
          icon: <Icon icon={socialFacebook} />,
          url: '#1',
        },
        {
          id: 2,
          icon: <Icon icon={socialDribbble} />,
          url: '#1',
        },
        {
          id: 3,
          icon: <Icon icon={socialGoogleplus} />,
          url: '#1',
        },
        {
          id: 4,
          icon: <Icon icon={socialSkype} />,
          url: '#1',
        },
        {
          id: 5,
          icon: <Icon icon={socialTwitter} />,
          url: '#1',
        },
      ],
    },
    {
      id: 3,
      avatar: member3,
      name: 'Naina Cooper',
      designation: 'Marketing Manager',
      social_links: [
        {
          id: 1,
          icon: <Icon icon={socialFacebook} />,
          url: '#1',
        },
        {
          id: 2,
          icon: <Icon icon={socialDribbble} />,
          url: '#1',
        },
        {
          id: 3,
          icon: <Icon icon={socialGoogleplus} />,
          url: '#1',
        },
        {
          id: 4,
          icon: <Icon icon={socialSkype} />,
          url: '#1',
        },
        {
          id: 5,
          icon: <Icon icon={socialTwitter} />,
          url: '#1',
        },
      ],
    },
  ],
};

/* ------------------------------------ */
// News section data
/* ------------------------------------ */
export const newsData = [
  {
    id: 1,
    title: 'Cash Discount with payment plan !',
    excerpt:
      'You can enjoy a cash discount on all our villas with a payment plan. We are offering up to 42% discount.',
    link_text: 'Read',
    link_url: '#',
  },
  {
    id: 2,
    title: '41% Cash Discount over 3 years !!!',
    excerpt:
      'Payment plan that suits you. You can enjoy a cash discount and pay over 3 years.',
    link_text: 'Read',
    link_url: '#',
  },
  {
    id: 3,
    title: '5% Down payment and 8 years installments',
    excerpt: 'Payment plan 8 years and delivery 4 years',
    link_text: 'Read',
    link_url: '#',
  },
];

/* ------------------------------------ */
// Testimonial data
/* ------------------------------------ */
export const testimonialData = {
  title: 'TESTIMONIAL',
  slogan: 'What Our Clients Say',
  reviews: [
    {
      id: 0,
      name: 'Parker Joe',
      designation: 'Co-Founder & CEO',
      username: '@amader craft',
      account_url: '#',
      comment:
        'Get working experience to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.',
      avatar: 'https://randomuser.me/api/portraits/men/45.jpg',
    },
    {
      id: 1,
      name: 'Britney havana',
      designation: 'Co-Founder & CEO',
      username: '@Light’s craft',
      account_url: '#',
      comment:
        'Impressed with master class support of the team and really look forward for the future.Really, really well made! Love that each component is handmade and customized. Great Work :)',
      avatar: 'https://randomuser.me/api/portraits/women/63.jpg',
    },
    {
      id: 2,
      name: 'June Spears',
      designation: 'Co-Founder & CEO',
      username: '@Eagle’s craft',
      account_url: '#',
      comment:
        'Get working experience to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.',
      avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
    },
    {
      id: 3,
      name: 'Jessica Parker',
      designation: 'Co-Founder & CEO',
      username: '@Earth’s craft',
      account_url: '#',
      comment:
        'Impressed with master class support of the team and really look forward for the future.Really, really well made! Love that each component is handmade and customized. Great Work !',
      avatar: 'https://randomuser.me/api/portraits/men/63.jpg',
    },
    {
      id: 4,
      name: 'Django Dsuja',
      designation: 'Co-Founder & CEO',
      username: '@Moon’s craft',
      account_url: '#',
      comment:
        'Impressed with master class support of the team and really look forward for the future.Really, really well made! Love that each component is handmade and customized. Great Work :)',
      avatar: 'https://randomuser.me/api/portraits/men/43.jpg',
    },
  ],
};

export const galleryData = [
  {
    id: 1,
    thumb_url: galleryImg1,
    name: 'Eco friendly',
    link: '#',
  },
  {
    id: 2,
    thumb_url: galleryImg2,
    name: 'Living',
    link: '#',
  },
  {
    id: 3,
    thumb_url: galleryImg3,
    name: 'Corner',
    link: '#',
  },
  {
    id: 4,
    thumb_url: galleryImg4,
    name: 'Wall decor',
    link: '#',
  },
  {
    id: 5,
    thumb_url: galleryImg5,
    name: 'Restroom',
    link: '#',
  },
];

/* ------------------------------------ */
// Newsletter data
/* ------------------------------------ */
export const newsletterData = {
  title: 'SUBSCRIBE NEWSLETTER',
  slogan: 'Don’t Miss Out Any Offer',
  note: 'Note: Please call us at 12pm to 8am. otherwise our customer supporter will not available to reach your call, but you can drop mail anytime.',
};

export const footerData = {
  logo: logo,
  mail: 'n.ansary@nxmine.com',
  phone: '+20 101 539 7020',
  socialLinks: [
    {
      id: 1,
      icon: <Icon icon={facebook} />,
      name: 'facebook',
      link: '#',
    },
    {
      id: 2,
      icon: <Icon icon={dribbble} />,
      name: 'dribbble',
      link: '#',
    },
    {
      id: 3,
      icon: <Icon icon={googlePlus} />,
      name: 'googlePlus',
      link: '#',
    },
    {
      id: 4,
      icon: <Icon icon={skype} />,
      name: 'skype',
      link: '#',
    },
    {
      id: 5,
      icon: <Icon icon={twitter} />,
      name: 'twitter',
      link: '#',
    },
  ],
  menuWidgets: [
    {
      id: 1,
      title: 'ABOUT US',
      menu: [
        {
          id: 1,
          text: 'Support Center',
          link: '#',
        },
        {
          id: 2,
          text: 'Customer Support',
          link: '#',
        },
        {
          id: 3,
          text: 'About Us',
          link: '#',
        },
        {
          id: 4,
          text: 'Copyright',
          link: '#',
        },
        {
          id: 5,
          text: 'Popular Campaign',
          link: '#',
        },
      ],
    },
    {
      id: 2,
      title: 'OUR INFORMATION',
      menu: [
        {
          id: 1,
          text: 'Return Policy',
          link: '#',
        },
        {
          id: 2,
          text: 'Privacy Policy',
          link: '#',
        },
        {
          id: 3,
          text: 'Terms & Conditions',
          link: '#',
        },
        {
          id: 4,
          text: 'Site Map',
          link: '#',
        },
        {
          id: 5,
          text: 'Store Hours',
          link: '#',
        },
      ],
    },
    {
      id: 3,
      title: 'MY ACCOUNT',
      menu: [
        {
          id: 1,
          text: 'Press inquiries',
          link: '#',
        },
        {
          id: 2,
          text: 'Social media directories',
          link: '#',
        },
        {
          id: 3,
          text: 'Images & B-roll',
          link: '#',
        },
        {
          id: 4,
          text: 'Permissions',
          link: '#',
        },
        {
          id: 5,
          text: 'Speaker requests',
          link: '#',
        },
      ],
    },
    {
      id: 4,
      title: 'POLICY',
      menu: [
        {
          id: 1,
          text: 'Application security',
          link: '#',
        },
        {
          id: 2,
          text: 'Software principles',
          link: '#',
        },
        {
          id: 3,
          text: 'Unwanted software policy',
          link: '#',
        },
        {
          id: 4,
          text: 'Responsible supply chain',
          link: '#',
        },
      ],
    },
  ],
};

/* ------------------------------------ */
// social profile
/* ------------------------------------ */
export const socialProfile = [
  {
    id: 1,
    icon: 'flaticon-facebook-logo',
    link: 'https://www.facebook.com/nxmine/',
  },
  // {
  //   id: 2,
  //   icon: 'flaticon-twitter-logo-silhouette',
  //   link: '#',
  // },
  // {
  //   id: 3,
  //   icon: 'flaticon-instagram',
  //   link: '#',
  // },
  // {
  //   id: 4,
  //   icon: 'flaticon-tumblr-logo',
  //   link: '#',
  // },
  // {
  //   id: 5,
  //   icon: 'flaticon-dribble-logo',
  //   link: '#',
  // },
];
