// components/LeadForm.js
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Input from 'common/components/Input';
import styled from 'styled-components';
import Button from '../Button';
import LoginDialog from '../Dialog/loginDialog';
import LoginModal from 'containers/Interior/LoginModal';

const propertyTypes = ['S Villa', 'Standalone', 'Townhouse'];

const FormContainer = styled.form`
  background-color: rgba(0, 0, 0, 0.3); /* 70% opacity */
  color: white;
  padding: 20px;
  border-radius: 20px;
`;

const PropertyTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const CommunicationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ErrorInput = styled(Input)`
  border: ${(props) => (props.error ? '1px solid red ' : '0px solid #ccc')};
  border-radius: 5px;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 5px;
`;

const LeadForm = () => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      propertyTypes: [], // Array for multi-select
      preferredContact: 'phone', // Preferred method of communication
      honeypot: '', // Hidden field for bot detection
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address'),
      phone: Yup.string().required('Required'),
      propertyTypes: Yup.array(),
      preferredContact: Yup.string()
        .oneOf(['phone', 'whatsapp'], 'Invalid preferred contact method')
        .required('Required'),
      honeypot: Yup.string(), // Honeypot field should not be filled
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (values.honeypot !== '') {
        // If honeypot field is filled, it's likely a bot submission
        console.warn('Bot detected!');
        setSubmitting(false);
        return;
      }

      try {
        await axios.post('/api/submitLead', {
          name: values.name,
          email: values.email,
          phone: values.phone,
          propertyTypes: values.propertyTypes,
          preferredContact: values.preferredContact,
        });
        alert(
          'Thank you! Your submission has been received, will contact you shortly.'
        );
        resetForm();
      } catch (error) {
        console.error('Error submitting form: ', error);
        alert('There was an error submitting your form.');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      formik.setFieldValue('propertyTypes', [
        ...formik.values.propertyTypes,
        value,
      ]);
    } else {
      formik.setFieldValue(
        'propertyTypes',
        formik.values.propertyTypes.filter((type) => type !== value)
      );
    }
  };

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <div>
        <ErrorInput
          id="name"
          name="name"
          inputType="text"
          placeholder="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={formik.handleFocus}
          error={formik.touched.name && formik.errors.name}
        />
        {formik.touched.name && formik.errors.name ? (
          <ErrorMessage>{formik.errors.name}</ErrorMessage>
        ) : null}
      </div>
      <br></br>
      <div>
        <ErrorInput
          id="email"
          name="email"
          inputType="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={formik.handleFocus}
          error={formik.touched.email && formik.errors.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <ErrorMessage>{formik.errors.email}</ErrorMessage>
        ) : null}
      </div>
      <br></br>
      <div>
        <ErrorInput
          id="phone"
          name="phone"
          inputType="tel"
          placeholder="Phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={formik.handleFocus}
          error={formik.touched.phone && formik.errors.phone}
        />
        {formik.touched.phone && formik.errors.phone ? (
          <ErrorMessage>{formik.errors.phone}</ErrorMessage>
        ) : null}
      </div>
      <br></br>
      <div>
        <PropertyTypeContainer>
          {propertyTypes.map((type) => (
            <div key={type}>
              <label>
                <input
                  type="checkbox"
                  name="propertyTypes"
                  value={type}
                  checked={formik.values.propertyTypes.includes(type)}
                  onChange={handleCheckboxChange}
                />
                {type}
              </label>
            </div>
          ))}
        </PropertyTypeContainer>
      </div>
      <br></br>
      <div>
        <CommunicationContainer>
          <label>Contact by</label>
          <div>
            <label>
              <input
                type="radio"
                name="preferredContact"
                value="phone"
                checked={formik.values.preferredContact === 'phone'}
                onChange={formik.handleChange}
              />
              Phone
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                name="preferredContact"
                value="whatsapp"
                checked={formik.values.preferredContact === 'whatsapp'}
                onChange={formik.handleChange}
              />
              WhatsApp
            </label>
          </div>
        </CommunicationContainer>

        {formik.touched.preferredContact && formik.errors.preferredContact ? (
          <ErrorMessage>{formik.errors.preferredContact}</ErrorMessage>
        ) : null}
      </div>

      {/* Honeypot field for bot detection */}
      <Input
        id="honeypot"
        name="honeypot"
        inputType="text"
        value={formik.values.honeypot}
        onChange={formik.handleChange}
        style={{ display: 'none' }}
      />
      <br></br>
      <ButtonsContainer>
        <Button
          className={styled.button}
          type="submit"
          disabled={formik.isSubmitting}
          title="Submit"
        >
          {formik.isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>

        <LoginDialog
          label={
            <Button
              className={styled.button}
              // disabled={formik.isSubmitting}
              title="AI Agent"
              icon={<i className="flaticon-conversation" />}
            >
              {/* {formik.isSubmitting ? 'Submitting...' : 'Submit'} */}
            </Button>
          }
          content={<LoginModal />}
        />
      </ButtonsContainer>
    </FormContainer>
  );
};

export default LeadForm;
