import React, { Fragment, useState, useEffect } from 'react';
import Link from 'next/link';
import { Icon } from 'react-icons-kit';
import { iosEmailOutline } from 'react-icons-kit/ionicons/iosEmailOutline';
import { iphone } from 'react-icons-kit/ionicons/iphone';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Input from 'common/components/Input';
import GlideCarousel from 'common/components/GlideCarousel';
import GlideSlide from 'common/components/GlideCarousel/glideSlide';
import { CircleLoader } from '../interior.style';
import BannerWrapper, {
  Container,
  ContentArea,
  HighlightedText,
  FormWrapper,
  ButtonGroup,
  CarouselArea,
} from './banner.style';

import { bannerData } from 'common/data/Interior';
import { iosArrowThinRight } from 'react-icons-kit/ionicons/iosArrowThinRight';
import { BellIcon } from '@radix-ui/react-icons';
import { height } from 'styled-system';
import LeadForm from 'common/components/Forms/LeadForm';

const Banner = () => {
  const { discount, discountLabel, title, text, carousel } = bannerData;
  const glideOptions = {
    type: 'carousel',
    perView: 3,
    gap: 20,
    breakpoints: {
      1200: {
        perView: 2,
      },
      667: {
        perView: 2,
      },
      480: {
        perView: 1,
      },
    },
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);

  const [state, setState] = useState({ email: '', valid: '' });
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleOnChange = (e) => {
    let value = '';
    if (e.target.value.match(emailRegex)) {
      if (e.target.value.length > 0) {
        value = e.target.value;
        setState({ ...state, email: value, valid: 'valid' });
      }
    } else {
      if (e.target.value.length > 0) {
        setState({ ...state, valid: 'invalid' });
      } else {
        setState({ ...state, valid: '' });
      }
    }
  };

  const handleSubscriptionForm = (e) => {
    e.preventDefault();
    if (state.email.match(emailRegex)) {
      console.log(state.email);
      setState({ email: '', valid: '' });
    }
  };

  return (
    <BannerWrapper>
      <Container>
        <ContentArea>
          <HighlightedText>
            <strong>{discount}</strong> {discountLabel}
          </HighlightedText>
          <Heading as="h1" content={title} />
          <Text content={text} />
          {/* <FormWrapper onSubmit={handleSubscriptionForm}>
            <Input
              className={state.valid}
              type="number"
              placeholder="Enter mobile number"
              icon={<Icon icon={iphone} />}
              iconPosition="left"
              required={true}
              onChange={handleOnChange}
              aria-label="mobile"
            />

            <br></br>

            <Input
              className={state.valid}
              type="email"
              placeholder="Enter email address"
              icon={<Icon icon={iosEmailOutline} />}
              iconPosition="left"
              required={true}
              onChange={handleOnChange}
              aria-label="email"
            />

            <ButtonGroup>
              <Button
                type="submit"
                colors="primaryWithBg"
                title="FREE CONSULT"
              />
              <Button
                title="EXPLORE MORE"
                variant="textButton"
                icon={<i className="flaticon-next" />}
              />
            </ButtonGroup>
          </FormWrapper> */}
          <br></br>
          <LeadForm></LeadForm>
          <br></br>
        </ContentArea>
        {/* End of content section */}

        <CarouselArea>
          {loading ? (
            <GlideCarousel
              carouselSelector="interior_carousel"
              options={glideOptions}
              nextButton={<span className="next_arrow" />}
              prevButton={<span className="prev_arrow" />}
            >
              <Fragment>
                {carousel.map((item) => (
                  <GlideSlide key={`carousel_key${item.id}`}>
                    <Link href={''} className="item_wrapper">
                      <Image src={item.thumb_url?.src} alt={item.title} />
                      {/* <Heading as="h4" content={item.title} /> */}
                    </Link>
                  </GlideSlide>
                ))}
              </Fragment>
            </GlideCarousel>
          ) : (
            <CircleLoader>
              <div className="circle"></div>
              <div className="circle"></div>
            </CircleLoader>
          )}
        </CarouselArea>
        {/* End of carousel section */}
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
